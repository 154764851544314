export default {
    en: {
        sock:{
            title: 'ARE YOU READY?',
            intro1: 'Good to know:',
            step1: 'The colours of the material may look slightly different on the screen. Make sure to check out the color swatches and the sample socks. The right sock is the same as the left.',
            step2: 'Choose 2 different colours of thread for a nice, mixed pattern.',
            step3: 'You can’t return your socks.',
            button: 'START DESIGNING',
        },
        scarf:{
            title: 'ARE YOU READY?',
            intro1: 'Good to know:',
            step1: 'The colours of the material may look slightly different on the screen. Make sure to check out the colour swatches and the sample scarves.',
            step2: 'Choose 2 different colours of thread for a nice, mixed pattern.',
            step3: 'You have 40 minutes to finish your design before it’s the next designer’s turn.',
            step4: 'We’ll mail you your scarf as soon as it’s ready.',
            step5: 'You can’t return your scarf.',
            intro2: 'An employee will be with you soon and then you can start!.',
            button: 'START DESIGNING',
        }
    },
    nl: {
        sock: {
            title: 'BEN JE ER KLAAR VOOR?',
            intro1: 'Goed om te weten:',
            step1: 'Je mixt steeds twee gekleurde draden tot een nieuwe kleur, in totaal kun je vijftien verschillende kleuren maken met de zes basiskleuren. ',
            step2: 'De kleuren zien er op het scherm soms anders uit dan in het echt, kijk dus goed naar de kleurstalen en de voorbeeldsokken in de ruimte. De rechter- en linkersok zijn hetzelfde.',
            step3: 'Je kunt je sokken niet retourneren.',
            button: 'BEGIN MET DESIGNEN',
        },
        scarf: {
            title: 'BEN JE ER KLAAR VOOR?',
            intro1: 'Goed om te weten:',
            step1: 'De kleuren van het materiaal zien er op het scherm soms anders uit dan in het echt. Kijk dus goed naar de voorbeeldsjaals en de kleurstalen.',
            step2: 'Je mixt steeds twee gekleurde draden tot een nieuwe kleur, in totaal kun je achtentwintig verschillende kleuren maken met de acht basiskleuren.',
            step3: 'Je hebt 40 minuten om je design af te maken, daarna is de volgende aan de beurt.',
            step4: 'Zodra je sjaal klaar is, sturen we ‘m naar je op.',
            step5: 'Je kunt je sjaal niet retourneren.',
            intro2: 'Nog even wachten op een medewerker en je kunt aan de slag!',
            button: 'BEGIN MET DESIGNEN',
        }
    }
};
