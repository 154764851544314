

























/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';
import { app } from '@config';
import PageMain from '@part/layout/PageMain.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import lang from '@lang/ready.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
        ArrowForwardSvg
    },
    props: [],
    data() {
        return {
        };
    },
    computed: {
        ...mapState(['sessionData', 'colorChoices']),
        lang(){
            return lang[this.activeLang()];
        }
    },
    methods: {
        ...mapMutations(['clearColorChoices']),
    },
    mounted() {
        this.clearColorChoices();
        this.selectStripe(-1);
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dev extends Vue {}
